<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<ValidationProvider
		ref="validationProvider"
		v-slot="{ validated, invalid, failedRules }"
		:vid="validationId"
		:immediate="validateImmediately"
		:skip-if-empty="!validateIfEmpty"
		:rules="validationRules"
		:mode="validationMode"
		:disabled="validationDisabled"
		slim
	>
		<div class="validationGroup">
			<BFormGroup
				:id="formGroupId"
				:label="label"
				:label-for="selectId"
				:state="getState(validated, invalid)"
				@click.native="$refs.validationProvider.validate()"
			>
				<BFormSelect
					:id="selectId"
					:ref="selectId"
					:value="value"
					v-bind="$attrs"
					:options="options"
					:plain="isPlain"
					:aria-invalid="invalid"
					:aria-describedby="invalid ? feedbackId : null"
					:aria-required="!validationDisabled"
					:required="!validationDisabled"
					:data-test-automation-id="getAutomationId('select')"
					:data-tracking-id="getAutomationId('select')"
					:style="value === '' ? 'color: #818181' : ''"
					@change="onChange"
					@blur.native="onBlur($event)"
				>
					<template #first>
						<BFormSelectOption value="" disabled :selected="true">
							{{ computedPlaceholder }}
						</BFormSelectOption>
					</template>
				</BFormSelect>
			</BFormGroup>
			<BFormInvalidFeedback
				:id="feedbackId"
				:force-show="false"
				aria-live="polite"
				:data-test-automation-id="getAutomationId('select')"
				>{{ getFeedback(validated, failedRules) }}
			</BFormInvalidFeedback>
		</div>
	</ValidationProvider>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import IdMixin from '@/mixins/id';
import Vue from 'vue';
import Component from 'vue-class-component';
import { ValidationProvider } from 'vee-validate';
import { BFormGroup, BFormSelect, BFormInvalidFeedback, BFormSelectOption } from 'bootstrap-vue';

// @vue/component
@Component({
	name: 'BaseSelect',
	components: {
		ValidationProvider,
		BFormGroup,
		BFormSelect,
		BFormInvalidFeedback,
		BFormSelectOption
	},
	mixins: [IdMixin],
	inheritAttrs: false,
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		// Note:  The default is "undefined".  That way the prop is not sent to the child components.
		label: {
			type: String,
			default: undefined
		},
		placeholder: {
			type: String,
			default: ''
		},
		value: {
			type: [String, Object, Number],
			default: null
		},
		isPlain: {
			type: Boolean,
			default: true
		},
		options: {
			type: Array,
			default() {
				return [];
			}
		},
		focusOnLoad: {
			type: Boolean,
			default: false
		},
		/**
		 * This prop controls if the field is validated immediatly
		 */
		validateImmediately: {
			type: Boolean,
			default: false
		},
		/**
		 * This prop controls if the validate if the field is empty
		 */
		validateIfEmpty: {
			type: Boolean,
			default: true
		},
		/**
		 * This prop controls the validation id. This is needed when cross field validation
		 */
		validationId: {
			type: String,
			default: ''
		},
		/**
		 * This prop controls the validation rules.
		 */
		validationRules: {
			type: [Object, String],
			default: ''
		},
		/**
		 * This prop controls the validation messages for each rules.
		 */
		validationMessages: {
			type: [Object, String],
			default: ''
		},
		/**
		 * This prop controls when the validation is triggered
		 */
		validationMode: {
			type: String,
			default: 'eager'
		},
		/**
		 * This prop controls when the validation is triggered
		 */
		validationDisabled: {
			type: Boolean,
			default: false
		},
		/**
		 * This prop is used for test automation Id's
		 */
		automationId: {
			type: String,
			required: true
		}
	}
})
export default class BaseSelect extends Vue {
	formGroupId = this.getUniqueId('form-group');
	feedbackId = this.getUniqueId('feedback');
	selectId = this.getUniqueId('select');

	formGroupId = this.getUniqueId('form-group');
	/**
	 * The unique id for the feedback
	 * @return {string}
	 */
	feedbackId = this.getUniqueId('feedback');
	/**
	 * The unique id for the input
	 * @return {string}
	 */
	inputId = this.getUniqueId('input');

	mounted() {
		this.$nextTick(() => {
			if (this.focusOnLoad) {
				this.$refs[this.selectId].focus();
			}
		});
	}

	get computedPlaceholder() {
		return this.placeholder !== '' ? this.placeholder : this.$t('placeholder');
	}

	/**
	 * Returns the feedback
	 *
	 * @param validated {Boolean} Flag is field is validated.
	 * @param failedRules {Object} The object of failed rules.
	 */
	getFeedback(validated, failedRules) {
		let message = null;
		if (validated && failedRules) {
			message = this.validationMessages[Object.keys(failedRules)[0]];
		}
		return message;
	}
	/**
	 * Returns the state
	 *
	 * @param validated {Boolean} Flag indicating if field is validated.
	 * @param invalid {Object} Flag indicating if field is valid
	 */
	getState(validated, invalid) {
		return validated && invalid ? false : null;
	}
	/**
	 * Handle on change event.
	 */
	onChange(value) {
		this.$emit('change', value);
	}
	/**
	 * Handle on blur event.
	 * @param event The focus event.
	 */
	onBlur(event) {
		this.$refs.validationProvider.validate();
		this.$emit('blur', event);
	}
}
</script>

<!-- *************************************************************************
	STYLE
	************************************************************************* -->

<style lang="scss" scoped>
select {
	font-size: 15px;
	& option:not(:first-of-type) {
		color: $black;
	}
	& option:first-of-type {
		color: #818181;
	}
}
.form-control {
	box-shadow: none;
	font-weight: 400;
	font-family: 'Lato', sans-serif;
	height: 28px;
	background-color: $input-background-white;
	border-color: $input-border-white;
	border-radius: 5px;

	&:focus {
		border-color: $input-border-focus-blue;
	}
	&.is-valid,
	&.is-invalid {
		background-image: none;
	}
	&.is-invalid {
		border-color: $error-red;
		box-shadow: none;
	}
}
select.form-control {
	height: 37px;
}

.form-group {
	margin-bottom: 0rem;

	& ::v-deep label {
		font-family: 'Josefin Sans', sans-serif;
		font-size: 18px;
		font-weight: 400;
		line-height: 1.3;
		color: $label-grey;
		margin-bottom: 5px;
	}
}

.invalid-feedback {
	text-align: left;
	font-size: 14px;
	color: $error-red;
	line-height: 17px;
	font-weight: 400;
	background-color: $error-background-pink;
	border-color: $red;
	padding: 10px;
	height: auto;
	border-radius: 5px;
}

.validationGroup {
	margin-bottom: 1.5rem;
}
</style>

<i18n>
{
	"en": {
		"placeholder": "Please select..."
	},
	"fr": {
		"placeholder": "Veuillez sélectionner..."
	}
}
</i18n>
